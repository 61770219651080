import React from 'react'
import { Col, Row } from 'antd'
import { GiTeePipe, GiArtificialHive, GiWoodStick, GiDna2 } from 'react-icons/gi'
import { FaUser } from 'react-icons/fa'

import { AiFillInstagram, AiFillMail } from 'react-icons/ai'

import { graphql, Link } from 'gatsby'

import siteConfig from '../../data/siteConfig'

import { Tabs, About, Uber, About2, About3,  Sani, Glas, Hero, SEO, Wrapper, Timeline, GridImages, Intro } from '../components';
import 'antd/dist/antd.css'
import '../theme/index.scss'

import styles from './index.module.scss'

class Home extends React.Component {
  static defaultProps = {
    pageResources: {
      json: {
        data: {
          experienceGallery: { images: [] },
          hobbyGallery: { images: [] }
        }
      }
    }
  }

  render () {
    const { loading, data, pageResources } = this.props;
    const { experienceGallery, hobbyGallery, woodGallery } = pageResources.json.data;
    
    const filteredExperiencedImages = experienceGallery?.images.map(image => ({
      source: image.photo.file.url,
      title: image.photo.title,
      ...image.photo
    }))
    const filteredHobbyImages = hobbyGallery?.images.map(image => ({
      source: image.photo.file.url,
      title: image.photo.title,
      ...image.photo
    }))
    const filteredWoodImages = woodGallery?.images.map(image => ({
      source: image.photo.file.url,
      title: image.photo.title,
      ...image.photo
    }))

    if (siteConfig.googleAnalyticsId === 'UA-000000000-1') {
      console.error('We do not use GA');
    }

    const title = siteConfig.siteTitle
    const {keywords} = siteConfig

    return (
      <>
        <SEO
          title={title}
          keywords={keywords}
        />

        <Hero
          heroImg={siteConfig.siteCover}
          // title={title}
        />

        <Wrapper className={this.props.className} >
          <div className={styles.pageContent} fluid>
            <Row justify="center">
              <Col span={24} className={styles.avatar}>
                <img
                  // className={styles.image}
                  styles='width=400px'
                  src='/images/claudio.jpg'
                  alt='haustechnik und Glasbläser'
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} xs={24} >
                <About title='' text={siteConfig.authorDescription}/>
              </Col>
             
              <Col md={12} lg={12} xs={24} >
                <About2 title='' text={siteConfig.kontakt} />
              </Col>
            </Row>
            <Row style={{margin:'-30px 0 0 0'}} >
              <Col md={24} lg={24} xs={24} >
                <About3  style={{textAlign:'left'}}title='' text={siteConfig.intro} />
              </Col>
              </Row>
            <div className={styles.separator} />
            <Tabs
              items ={[
                {
                  title: 'Haustechnik',
                  icon:  <GiTeePipe className="social-icon" size="36" />,
                  content: <>
                <Sani title='' text={siteConfig.haustechnik} texter={siteConfig.haustech} />
                            <GridImages images={filteredExperiencedImages} isLoading={loading} />
                          </>
                },
                {
                  title: 'Über mich',
                  icon:  <GiDna2 className="social-icon" size="36" />,
                  content: <>
                  <Glas title='' text={siteConfig.abouter} />
                            <Timeline />
                          </>
                },
                {
                  title: 'Glasbläser',
                  icon:  <GiArtificialHive className="social-icon" size="36" />,
                  content: <>
                                  <Glas title='' text={siteConfig.glas} />

                   <GridImages images={filteredHobbyImages} isLoading={loading} />
                   </>
                },
                {
                  title: 'Wood',
                  icon:  <GiWoodStick className="social-icon" size="36" />,
                  content:  <GridImages images={filteredWoodImages || []} isLoading={loading} />
                }
                
              ]}
            />
          </div>
        </Wrapper>
        <Wrapper>
        <Row style={{margin:'-20px -20px 0 0', textAlign: 'center'}} >
              <Col md={12} lg={12} xs={24} >
                <div>Email: <a href="malto:claudio@de-martino.ch">claudio@de-martino.ch</a></div>
              </Col>
              <Col md={12} lg={12} xs={24} >
              <div>Handy: <a href="tel:+41764540004">076 454 00 04</a></div>
              </Col>
              </Row>
              <Row style={{textAlign:'center', padding:'20px'}}>
                <Col md={24} lg={24} xs={24} >
                  <Link to="/impressum" >Impressum</Link>
                </Col>
              </Row>
      </Wrapper>
      </>

     
    )
  }
}

export const experienceGallery = graphql`
  {
    experienceGallery: contentfulPhotoGallery(contentful_id: {eq: "1pimwQakflqVioLm9cn6SC"}) {
      images {
        photo {
          file {
            fileName
            url
            contentType
          }
          title
          fluid(maxWidth: 1280) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
    
    hobbyGallery: contentfulPhotoGallery(contentful_id: {eq: "1TfXthEmsHciR8BNdaeWdA"}) {
      images {
        photo {
          file {
            fileName
            url
            contentType
          }
          title
          fluid(maxWidth: 1280) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
    woodGallery: contentfulPhotoGallery(contentful_id: {eq: "6tK0woItx0RYaxYMjhHl4Z"}) {
      images {
        photo {
          file {
            fileName
            url
            contentType
          }
          title
          fluid(maxWidth: 1280) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`

export default Home
